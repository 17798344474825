import * as React from "react";
import {
  Box, Text, Heading, Center, Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Layout from "../components/layout";
import Head from "../components/head";

const AboutPage = () => {
  return (
    <Layout>
      <Head title="Create 15 - About" description="Laser cutting and engraving in Johannesburg. Various materials like MDF, wood, bamboo and perspex." />

      <Center h="60px" color="black">
        <Heading as="h1">About</Heading>
      </Center>
      <Box textAlign="center" pb={4}>
        <Text fontSize="l">
          Create 15 is a small scale operation. You could call it a side gig.
        </Text>
        <Text fontSize="l">
          This website will change as new designs and ideas come to light.
        </Text>
        <Text fontSize="l" pt={2}>
          Have a look at the FAQ below for other answers.
        </Text>
      </Box>
      <Box mb="300px">
        <Accordion mb={4} allowMultiple>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Why is the website very empty?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Sorry the website is still a work in progress. We hope to make the website a larger part of the business soon. Possibly put some products up for sale through the website. Currently testing out some ideas, hang tight.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Do you sell products already made?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We have a few things that we do constantly like crystal grids and coasters. Email us for more information.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Do you deliver or do pickup?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              We prefer not to do pickups because the operation is run from our home. We do use couriers to ship the orders. However depending on the order and size we can make arrangements for pickup.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Do you do online orders?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Not yet but we working on getting this up and running. Stay tuned.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Layout>
  );
};

export default AboutPage;
